import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './cross-achievement.module.css';
import * as images from './images';

export const CrossAchievement = ({ id, teamLabel }) => {
  const { t } = useTranslation();
  const name = t('game.nextRound.achievementNames', { returnObjects: true })[
    id - 1
  ];
  return (
    <div className={styles.achievement}>
      <img
        className={styles.img}
        src={images[`achievement${id}`]}
        alt={id}
        title={name}
      />
      <span className={styles.label}>{teamLabel}</span>
      <span className={styles.name}>{name}</span>
    </div>
  );
};

CrossAchievement.propTypes = {
  id: PropTypes.number.isRequired,
  teamLabel: PropTypes.string.isRequired,
};
