import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { isEnvDevelopment } from './helpers';
import { DEFAULT_LOCALE } from './constants';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LOCALE,
    lng: DEFAULT_LOCALE,
    debug: isEnvDevelopment(),
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', lng);
});

export { i18n };
