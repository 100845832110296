import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './month.module.css';
import { Round } from '../../icons/round/round';
import { useAppStore } from '../../store/app';

export const Month = observer(({ round }) => {
  const { teamColor } = useAppStore();
  const { t } = useTranslation();
  const roundName = t('game.rounds', { returnObjects: true })[round - 1];
  return (
    <div className={styles.month}>
      <div className={styles.bg}>
        <Round color={teamColor} />
      </div>
      <div className={styles.monthName} style={{ color: teamColor }}>
        {roundName}
      </div>
      <div className={styles.round}>
        <div className={styles.roundValue}>{round}</div>
        {t('game.round')}
      </div>
    </div>
  );
});

Month.propTypes = {
  round: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
};
