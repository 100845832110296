import React from 'react';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './news-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';

export const NewsModal = observer(() => {
  const { showModal, closeModal, round, newsData } = useAppStore();
  const { t } = useTranslation();
  const { news = [], insides = [] } = newsData;
  const previousRound = round - 1;
  return (
    <Modal show={showModal === 'news'} onHide={closeModal} size="lg">
      <Modal.Title icon="news">
        {previousRound > 1
          ? t('game.news.title', { month: previousRound })
          : t('game.news.title_all')}
      </Modal.Title>
      <div className={cn(styles.newsBlock, styles.news)}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={100}
          autoHeightMax={300}
        >
          <div className={styles.newsList}>
            {news.map(item => (
              <div key={item} className={styles.newsItem}>
                {item}
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
      <Modal.Title icon="insides">
        {t('game.insides.title', { month: round })}
      </Modal.Title>
      <div className={styles.newsBlock}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={100}
          autoHeightMax={300}
        >
          <div className={styles.newsList}>
            {insides.map(item => (
              <div key={item} className={styles.newsItem}>
                {item}
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
    </Modal>
  );
});
