import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App.jsx';
import { Spinner } from './components/spinner/spinner.jsx';

// eslint-disable-next-line no-unused-vars
import { i18n } from './i18n';

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>,
  document.getElementById('app')
);
