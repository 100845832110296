/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import { Table } from 'react-bootstrap';
import styles from './help.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
// import { NodeLevel1Icon } from '../../icons/node-level-1/node-level-1';
// import { NodeLevel2Icon } from '../../icons/node-level-2/node-level-2';
// import { NodeLevel3Icon } from '../../icons/node-level-3/node-level-3';
// import { NodeLevel4Icon } from '../../icons/node-level-4/node-level-4';
// import { NodeLevel5Icon } from '../../icons/node-level-5/node-level-5';
import image1 from '../../assets/1336_screen_order_1.jpg';
import image2 from '../../assets/1336_screen_order_2.jpg';
import image3 from '../../assets/1336_screen_order_3.jpg';
import image4 from '../../assets/1336_screen_order_4.jpg';
import image5 from '../../assets/1336_screen_statistic.jpg';
import image6 from '../../assets/1336_screen_news.jpg';
import image7 from '../../assets/1336_screen_chat.jpg';
import level1 from '../../assets/node-level-1.png';
import level2 from '../../assets/node-level-2.png';
import level3 from '../../assets/node-level-3-full.png';
import level4 from '../../assets/node-level-4.png';
import level5 from '../../assets/node-level-5.png';

export const HelpModal = observer(() => {
  const { showModal, closeModal, isFullFaqAvailable } = useAppStore();
  const { t } = useTranslation();
  const renderView = ({ ...props }) => (
    <div className={styles.view} {...props} />
  );
  return (
    <Modal show={showModal === 'help'} onHide={closeModal} size="lg">
      <Modal.Title icon="help">{t('game.help.title')}</Modal.Title>
      <div className={styles.block}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={300}
          autoHeightMax={500}
          renderView={renderView}
        >
          {isFullFaqAvailable && (
            <>
              <p>
                <strong>Главная цель игры</strong> — заработать как можно больше
                победных баллов, исследуя и осваивая пересеченную местность.
              </p>
              <p>
                <strong>Как можно заработать баллы?</strong>
              </p>
              <p>Вы начинаете игру в одной из точек, и можете:</p>
              <ol>
                <li>
                  <strong>Захватывать точки на карте. </strong>Чем больше точек
                  вы контролируете, тем больше баллов зарабатываете
                </li>

                <li>
                  <strong>Развивать свои точки. </strong>Чем выше уровень
                  развития вашей точки, тем больше она приносит дохода в виде
                  «припасов». Припасы можно тратить на дальнейшее развитие
                  точки, либо на захват соседних точек.
                </li>

                <li>
                  <strong>Получать значки за захват контрольных точек. </strong>
                  Контрольные точки находятся в центре карты. За их захват вы
                  получите трофейный значок и дополнительные победные баллы.
                  Значок останется с вами, даже если точку потом захватит другая
                  команда.
                </li>

                <li>
                  <strong>Завоевывать переходящие призы. </strong>В игре
                  предусмотрены дополнительные призы за различные достижения.
                  Подробнее об этом можно прочитать ниже.
                </li>
              </ol>
              <p>
                <strong>Как захватить новую точку?</strong>
              </p>
              <ol>
                <li>
                  На карте местности <strong>кликаете на одну из точек</strong>,
                  которая вам принадлежит
                </li>

                <li>
                  В выпадающем окне выбираете «<strong>Захватить точку</strong>»
                </li>

                <li>
                  <strong>Выбираете точку</strong> в выпадающем списке
                </li>

                <li>
                  <strong>Выбираете количество припасов</strong>, которое вы
                  собираетесь потратить на присоединение страны
                </li>

                <li>
                  Нажимаете на зеленую кнопку «<strong>+</strong>» напротив
                  выбранного действия
                </li>
              </ol>
              <p>
                В любое время до конца раунда вы можете отменить это решение,
                нажав на красную кнопку «<strong>-</strong>» напротив
                соответствующего решения в разделе «
                <strong>Принятые решения</strong>».
              </p>

              <table className={styles.centeredTable}>
                <tr>
                  <td>
                    <img
                      className={styles.image}
                      src={image1}
                      width=""
                      alt="alt_text"
                    />

                    <p>
                      Из точки «Двойной пик» планируется атака на точку «Бурные
                      пороги»
                    </p>
                  </td>
                  <td>
                    <img
                      className={styles.image}
                      src={image2}
                      width=""
                      alt="alt_text"
                    />

                    <p>
                      Отмена атаки из точки «Двойной пик» на точку «Бурные
                      пороги»
                    </p>
                  </td>
                </tr>
              </table>

              <p>
                <strong>Как развивать точку?</strong>
              </p>
              <ol>
                <li>
                  На карте местности <strong>кликаете по иконке</strong> с
                  точкой, которая вам принадлежит
                </li>

                <li>
                  В выпадающем окне выбираете «<strong>Расширить лагерь</strong>
                  » и нажимаете на зеленую кнопку «<strong>+</strong>» напротив
                  выбранного действия
                </li>
              </ol>
              <p>
                Если у вас будет достаточно припасов для совершения этого
                действия, оно будет принято к исполнению. В любое время до конца
                раунда вы можете отменить это решение, нажав на красную кнопку «
                <strong>-</strong>» напротив соответствующего решения в разделе
                «<strong>Принятые решения</strong>».
              </p>

              <table className={styles.centeredTable}>
                <tr>
                  <td>
                    <img
                      className={styles.image}
                      src={image3}
                      width=""
                      alt="alt_text"
                    />
                    <p>
                      Планируется прокачать точку «Двойной пик» с уровня
                      «Палаточный лагерь» до уровня «Дозорная вышка»
                    </p>
                  </td>
                  <td>
                    <img
                      className={styles.image}
                      src={image4}
                      width=""
                      alt="alt_text"
                    />

                    <p>
                      Команда передумала прокачивать точку «Двойной пик» с
                      уровня «Палаточный лагерь» до уровня «Дозорная вышка»
                    </p>
                  </td>
                </tr>
              </table>

              <p>
                <strong>Где посмотреть статистику?</strong>
              </p>
              <p>
                Во вкладке «Моя команда» вы можете увидеть рейтинг команд,
                узнать результаты ваших действий в прошлом месяце, получить
                данные о захваченных точках, выяснить, какие точки на каком
                уровне развития находятся и сколько победных баллов вам
                приносят.
              </p>

              <table className={styles.centeredTable}>
                <tr>
                  <td>
                    <img
                      className={styles.image}
                      src={image5}
                      width=""
                      alt="alt_text"
                    />
                    <p>
                      Во вкладке «Моя команда» можно увидеть всю статистику:
                      рейтинг команд, список захваченных точек, список событий
                      прошедшего дня, иконки достижений.
                    </p>
                  </td>
                  <td></td>
                </tr>
              </table>

              <p>
                <strong>Что еще можно делать в игре?</strong>
              </p>
              <ul>
                <li>
                  <strong>УСИЛИВАТЬ ПАТРУЛИ</strong>. Чем больше припасов вы
                  потратите на усиление патрулей, тем сложнее будет конкурентам
                  захватить себе вашу точку.
                </li>

                <li>
                  <strong>ПЕРЕРАСПРЕДЕЛЯТЬ ПРИПАСЫ</strong>. Вы можете
                  перераспределять припасы между своими точками. Отправленные
                  припасы перейдутна другую точку в начале следующего месяца.
                </li>

                <li>
                  <strong>ЗАБОТИТЬСЯ О ПРИРОДЕ</strong>. Чем больше припасов вы
                  потратите на заботу о природе, тем больший «доход» будут
                  приносить точки. Это повлияет не только на ваши точки, но и на
                  все точки других команд.
                </li>

                <li>
                  <strong>УЗНАВАТЬ НОВОСТИ</strong>. Во вкладке «Новости» вас
                  ждет информация о подстерегающих опасностях или новых
                  доступных трофейных значках.
                </li>

                <li>
                  <strong>ОБЩАТЬСЯ С ДРУГИМИ КОМАНДАМИ</strong>. Во вкладке
                  «Чаты» вы можете вступить в диалог с любой другой командой,
                  чтобы обменяться новостями или заключить союз. Например, чтобы
                  совместно атаковать третью команду, либо наоборот,
                  договориться о ненападении друг на друга.
                </li>
              </ul>
              <table className={styles.centeredTable}>
                <tr>
                  <td>
                    <img
                      className={styles.image}
                      src={image6}
                      width=""
                      alt="alt_text"
                    />

                    <p>
                      Во вкладке «Новости» можно свежую информацию, а также
                      донесения разведчиков
                    </p>
                  </td>
                  <td>
                    <img
                      className={styles.image}
                      src={image7}
                      width=""
                      alt="alt_text"
                    />
                    <p>
                      Во вкладке «Чаты» можно общаться с другими командами и
                      договариваться о совместных операциях
                    </p>
                  </td>
                </tr>
              </table>
            </>
          )}

          <p>
            <strong>{t('game.help.coefficientTitle')}</strong>
          </p>

          <Table>
            <tr style={{ textTransform: 'capitalize' }}>
              <td>
                <strong>{t('game.stats.level')} 1</strong>
              </td>
              <td>
                <strong>{t('game.stats.level')} 2</strong>
              </td>
              <td>
                <strong>{t('game.stats.level')} 3</strong>
              </td>
              <td>
                <strong>{t('game.stats.level')} 4</strong>
              </td>
              <td>
                <strong>{t('game.stats.level')} 5</strong>
              </td>
            </tr>
            <tr className={styles.levels}>
              <td>
                <img src={level1} alt="Уровень 1" width={85} />
              </td>
              <td>
                <img src={level2} alt="Уровень 2" width={95} />
              </td>
              <td>
                <img src={level3} alt="Уровень 3" width={95} />
              </td>
              <td>
                <img src={level4} alt="Уровень 4" width={90} />
              </td>
              <td>
                <img src={level5} alt="Уровень 5" width={90} />
              </td>
            </tr>
            <tr>
              <td>{t('game.levels.1')}</td>
              <td>{t('game.levels.2')}</td>
              <td>{t('game.levels.3')}</td>
              <td>{t('game.levels.4')}</td>
              <td>{t('game.levels.5')}</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>{t('game.help.whatGives')}</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>{t('game.help.toCapture')}</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>150</td>
              <td>300</td>
              <td>500</td>
              <td>800</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>{t('game.help.toNextLevel')}</strong>
              </td>
            </tr>
            <tr>
              <td>50</td>
              <td>200</td>
              <td>500</td>
              <td>1000</td>
              <td>нет</td>
            </tr>
            <tr>
              <td colSpan="5">
                <strong>{t('game.help.score')}</strong>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>5</td>
              <td>10</td>
              <td>20</td>
            </tr>
          </Table>

          {isFullFaqAvailable && (
            <>
              <p>
                <strong>Список переходящих призов</strong>
              </p>
              <p>
                В игре есть несколько переходящих призов. Каждый из них приносит
                дополнительные победные баллы.
              </p>
              <ul>
                <li>
                  <strong>За широкую экспансию</strong> (самое большое число
                  захваченных точек)
                </li>

                <li>
                  <strong>За интенсивное развитие</strong> (самое большое число
                  точек 5-го уровня)
                </li>

                <li>
                  <strong>За охрану природы</strong> (самое большое количество
                  припасов, вложенных в заботу о природе)
                </li>

                <li>
                  <strong>За скаутские успехи</strong> (самое большое число
                  заработанных значков-ачивок)
                </li>

                <li>
                  <strong>За доминирование</strong> (самое большое число
                  захваченных контрольных точек)
                </li>
              </ul>
              <p>Удачной игры!</p>
            </>
          )}
        </Scrollbars>
      </div>
    </Modal>
  );
});
