import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import styles from './welcome-modal.module.css';
import { Modal } from '../../components/modal/modal';
import { useAppStore } from '../../store/app';
import { Logo } from '../../icons/logo/logo';
import line from '../../assets/line-small.svg';

export const WelcomeModal = observer(() => {
  const {
    showModal,
    closeModal,
    teamColor,
    teamColorName,
    teamName,
  } = useAppStore();
  const { t } = useTranslation();
  return (
    <Modal
      show={showModal === 'welcome'}
      onHide={closeModal}
      size="lg"
      closable={false}
      dialogClassName={styles.modal}
    >
      <div className={styles.modalContainer}>
        <div className={styles.logo}>
          <Logo color="#FFFFFF" secondColor="#D93D2C" />
        </div>
        <div className={styles.gameName}>{t('game.name')}</div>
        <div className={styles.gameDescr}>
          <Trans>{t('game.description')}</Trans> <img src={line} alt="game" />
        </div>
        <div className={styles.title}>{t('game.welcome.title')}</div>
        <div className={styles.description}>
          <Trans
            components={{
              colorTag: (
                <span
                  className={styles.teamColor}
                  style={{
                    backgroundColor: teamColor,
                    // color: getStrokeColorByColor(TEAM_COLORS[team_id]),
                  }}
                />
              ),
            }}
          >
            {t('game.welcome.descriptionColor', { colorName: teamColorName })}
          </Trans>
          <br />
          <Trans>{t('game.welcome.descriptionTeam', { teamName })}</Trans>
          <br />
          <br />
          {t('game.welcome.descriptionGoal')}

          <ul className={styles.list}>
            {t('game.welcome.descriptionGoals', { returnObjects: true }).map(
              item => (
                <li key={item}>{item}</li>
              )
            )}
          </ul>
        </div>
        <Button className={styles.button} onClick={closeModal}>
          {t('game.welcome.start')}
        </Button>
      </div>
    </Modal>
  );
});
