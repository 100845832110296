/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './achievement.module.css';
import * as images from './images';

export const AchievementFull = ({
  id,
  name,
  node_name,
  is_active,
  // is_available,
  availability,
}) => {
  let image = images.achievementUnavailable;
  const { t } = useTranslation();

  if (is_active) {
    image = images[`achievement${id}`];
  } else {
    switch (availability) {
      case 1:
        image = images.achievementAvailable;
        break;
      case 2:
        image = images.achievementWasUnavailable;
        break;
      default:
        image = images.achievementUnavailable;
        break;
    }
  }

  const isUnavailable =
    (availability === 0 || availability === 2) && !is_active;

  return (
    <div
      className={cn(styles.achievement, {
        [styles.unavailable]: isUnavailable,
      })}
    >
      <img className={styles.img} src={image} alt={id} title={name} />
      <span className={styles.name}>{name}</span>
      {node_name && <span className={styles.label}>{node_name}</span>}
      {isUnavailable && (
        <span className={cn(styles.label, styles.unavailable)}>
          {t(
            `game.stats.${
              availability === 0
                ? 'achievement_unavailable_yet'
                : 'achievement_unavailable'
            }`
          )}
        </span>
      )}
    </div>
  );
};

AchievementFull.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  node_name: PropTypes.string.isRequired,
  is_active: PropTypes.bool,
  availability: PropTypes.number.isRequired,
};
