import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from '../command.module.css';
import { AddIcon } from '../../../icons/add/add';
import { formatCurrency } from '../../../helpers';

export const CommandWithOption = ({
  onAdd,
  label,
  availableMoney,
  option,
  Icon,
}) => {
  const { t } = useTranslation();
  const disabled = availableMoney < option.value;
  const commandLabel = `${label} «${t(`game.levels.${option.level}`)}»`;
  return (
    <div className={styles.command}>
      <span className={styles.icon}>
        <Icon />
      </span>
      <span className={styles.label}>{commandLabel}</span>
      <div className={styles.select}>
        {option.value && (
          <span className={styles.value}>{formatCurrency(option.value)}</span>
        )}
      </div>

      <button
        type="button"
        onClick={() => onAdd(option.value, commandLabel)}
        className={styles.button}
        disabled={disabled}
      >
        <AddIcon />
      </button>
    </div>
  );
};

CommandWithOption.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  availableMoney: PropTypes.number.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
  }),
  Icon: PropTypes.node.isRequired,
};
